import React from "react";
import PropTypes from "prop-types";

function Section({ bgColor, children }) {
  return (
    <section className={`my-[75px] text-white ${bgColor}`}>
      <div>{children}</div>
    </section>
  );
}

Section.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Section;
