import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";

function ButtonLink({ children, isInternal = true, href, className }) {
  if (isInternal) {
    return (
      <div className={className}>
        <Link
          activeClassName="border-indigo-300"
          className="border-b-2 border-transparent cursor-pointer main-menu hover:border-indigo-500"
          to={href}
        >
          {children}
        </Link>
      </div>
    );
  }

  return (
    <div className={className}>
      <span className="border-b-2 border-transparent cursor-pointer main-menu hover:border-indigo-500 active:border-indigo-300">
        <OutboundLink href={href} target="_blank" rel="noreferrer">
          {children}
        </OutboundLink>
      </span>
    </div>
  );
}

export default ButtonLink;
