import React from "react";
import Page from "./../components/Page";
import Section from "./../components/Section";
import SEO from "./../components/SEO";
import ButtonLink from "../components/ButtonLink";
import { StaticImage } from "gatsby-plugin-image";

function Features() {
  return (
    <Page>
      <SEO title="Features | OHIF" />
      <Section className="max-w-6xl mx-auto">
        <h1 className="text-5xl text-blue-100 mx-auto text-center">Features</h1>
        {/* 3 cards */}
        <div className="mt-10">
          <ThreeCardComponent />
        </div>
        {/* First section */}
        <div className="flex flex-col space-y-32 mt-32">
          <div>
            <h1 className="text-4xl text-blue-100 text-center" id="performance">
              Performance-focused web app
            </h1>
            <div className="mt-10 flex flex-col space-y-4 md:flex-row md:space-x-12 md:space-y-0">
              <div className="w-full md:w-1/2">
                <StaticImage src="../images/features-1.png" />
              </div>
              <div className="text-white flex flex-col w-full space-y-8 md:w-1/2">
                <div className="flex space-x-5 justify-start mt-8 md:mt-0">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-1-visualize.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Advanced visualization</h1>
                    <p className="text-blue-pale">
                      See all the details with support for multi-modal image
                      fusion, multi-planar reformatting, and more
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-1-speed.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">High-performance</h1>
                    <p className="text-blue-pale">
                      Speed up your work with GPU accelerated image rendering
                      and multi-threaded image decoding
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-1-web-app.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Web application</h1>
                    <p className="text-blue-pale">
                      Load cases from anywhere, instantly, with no installation
                      required. Supports all modern browsers.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-1-user-centered.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">User-centered design</h1>
                    <p className="text-blue-pale">
                      Professional product and interaction design with a focus
                      on usability
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Second section */}
          <div>
            <h1 className="text-4xl text-blue-100 text-center" id="workflow">
              Use cases
            </h1>
            <div className="mt-10 flex flex-col space-y-4 md:flex-row md:space-x-12 md:space-y-0">
              <div className="w-full md:w-1/2">
                <StaticImage src="../images/features-2.png" />
              </div>
              <div className="text-white flex flex-col w-full space-y-8 md:w-1/2">
                <div className="flex space-x-5 justify-start mt-8 md:mt-0">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-2-measure.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">
                      Measurement and image annotation
                    </h1>
                    <p className="text-blue-pale">
                      Draw from a robust library of easy-to-use annotation and
                      measurement tools
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-2-segmentation.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">3D Segmentation</h1>
                    <p className="text-blue-pale">
                      Precisely segment anatomical structures in 3D medical
                      images for advanced analysis and visualization
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-2-hanging-protocols.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Hanging Protocols</h1>
                    <p className="text-blue-pale">
                      Customize display layouts for efficient interpretation of
                      medical images with multi-planar reformatting and
                      multi-modal fusion support
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-2-microscope.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Microscopy</h1>
                    <p className="text-blue-pale">
                      Experience lightning-fast rendering and annotation
                      capabilities for high-resolution digital slide microscopy
                      images
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Third section */}
          <div>
            <h1 className="text-4xl text-blue-100 text-center" id="flexible">
              Flexible & extensible imaging platform{" "}
            </h1>
            <div className="mt-10 flex flex-col space-y-4 md:flex-row md:space-x-12 md:space-y-0">
              <div className="w-full md:w-1/2">
                <StaticImage src="../images/features-3.png" />
              </div>
              <div className="text-white flex flex-col w-full space-y-8 md:w-1/2">
                <div className="flex space-x-5 justify-start mt-8 md:mt-0">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-3-discuss.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">
                      Free, open-source, active community
                    </h1>
                    <p className="text-blue-pale">
                      Benefit from our thriving open-source community's
                      expertise, collaboration, and innovation for limitless
                      possibilities
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-3-components.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Reusable components</h1>
                    <p className="text-blue-pale">
                      Build custom workflows using a composable set of
                      professionally designed React user interface components.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-3-workflows.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Create custom workflows</h1>
                    <p className="text-blue-pale">
                      Provides a plugin framework for creating task-based
                      workflow modes which can re-use core functionality.
                    </p>
                  </div>
                </div>
                <div className="flex space-x-5 justify-start mt-8">
                  <div className="bg-[#020418] rounded flex items-center justify-center p-2">
                    <StaticImage
                      src="../images/icon-g-3-standards.png"
                      className="w-12"
                    />
                  </div>
                  <div className="">
                    <h1 className="text-lg">Standards compliant</h1>
                    <p className="text-blue-pale">
                      Flexibly connect to image archives with standard APIs such
                      as DICOMWeb and OpenID Connect.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div></div>
      </Section>
    </Page>
  );
}

function ThreeCardComponent() {
  return (
    <div className="text-white text-center text-2xl flex flex-col space-y-4 mt-5 sm:flex-row sm:space-x-4 sm:space-y-0">
      <ButtonLink
        isInternal={true}
        href={"/features#performance"}
        className={`w-full`}
      >
        <div className="card-style-features space-y-4">
          <div className="flex space-x-10 justify-center items-center">
            <StaticImage src="../images/icon-g-1-visualize.png" />
            <StaticImage src="../images/icon-g-1-speed.png" />
            <StaticImage src="../images/icon-g-1-web-app.png" />
          </div>
          <div className="text-white">
            <div>{"Performance focused"}</div>
            <div>{"web app"}</div>
          </div>
        </div>
      </ButtonLink>
      <ButtonLink
        className={`w-full`}
        isInternal={true}
        href={"/features#workflow"}
      >
        <div className="card-style-features space-y-4">
          <div className="flex space-x-10 justify-center items-center">
            <StaticImage src="../images/icon-g-2-segmentation.png" />
            <StaticImage src="../images/icon-g-2-hanging-protocols.png" />
            <StaticImage src="../images/icon-g-2-microscope.png" />
          </div>
          <div className="text-white">
            <div>{"Custom workflows and"}</div>
            <div>{"user experience"}</div>
          </div>
        </div>
      </ButtonLink>
      <ButtonLink
        className={`w-full`}
        isInternal={true}
        href={"/features#flexible"}
      >
        <div className="card-style-features space-y-4">
          <div className="flex space-x-10 justify-center items-center">
            <StaticImage src="../images/icon-g-3-discuss.png" />
            <StaticImage src="../images/icon-g-3-workflows.png" />
            <StaticImage src="../images/icon-g-3-standards.png" />
          </div>
          <div className="text-white">
            <div>{"Flexible & Extensible"}</div>
            <div>{"framework"}</div>
          </div>
        </div>
      </ButtonLink>
    </div>
  );
}
export default Features;
